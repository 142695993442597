<template>
  <list-template
    isDownload
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    :empty_text="empty_text"
    @onExportData="handDownload"
    @onHandle="handleClick"
    @onSearch="onSearch"
    @onReset="onReset"
    @onChangePage="handleCurrentChange"
    @onChange="onChange"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import http, { fileDownload } from '@/api'
import { setConfigOption, getConfigValue, setConfigValue } from '@/utils'
export default {
	_config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  data() {
    return {
      isRequest: false,
      searchConfig: [
        { placeholder: '搜索学生姓名', prop: 'student_name' },
        { placeholder: '搜索学生学号', prop: 'student_no' },
        { placeholder: '搜索辅导教师', prop: 'teacher_name' },
        { placeholder: '筛选起始日期', prop: 'month', tag: 'select', options: [] },
        { placeholder: '筛选校区', prop: 'school_id', tag: 'select', options: [], change: (id, search) => {
          if (search.class_id) search.class_id = ''
          this.getClass(id, search.grade_id)
        }},
        { placeholder: '筛选年级', prop: 'grade_id', tag: 'select', options: [], change: (id, search) => {
          if (search.class_id) search.class_id = ''
          this.getClass(search.school_id, id)
        }},
        { placeholder: '筛选行政班', prop: 'class_id', tag: 'select', options: [], config: { 'no-data-text': '请先选择校区和年级' } },
        { placeholder: '筛选是否完成辅导', prop: 'status', tag: 'select', options: [{label: '未完成', value: 0},{label: '已完成', value: 1}] },
      ],
      tableConfig: [
        { prop: 'student_name', label: '学生姓名', },
        { prop: 'student_no', label: '学号', },
        { prop: 'headmaster_name', label: '班主任姓名', },
        { prop: 'class_name', label: '行政班', },
        { prop: 'grade_name', label: '年级', },
        { prop: 'school_name', label: '校区', },
        { prop: 'day_describe', label: '起始日期', },
        { prop: 'status', label: '是否完成辅导', },
        { prop: 'words', label: '辅导内容字数', },
        { prop: 'org_teacher_name', label: '辅导教师', },
        { prop: 'coach_time', label: '辅导完成时间', },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      this.getDate()
      this.getSchool()
      this.getGrade()
    },
    getDate() {
      http({ url: '/teacher/moral-educationController/pc-cycle', pre: 'api2'}).then(res => {
        if(!res) return
        const data = res.map(item => ({ value: item.day_start + '-' + item.day_end, label: item.day_start + '-' + item.day_end }))
        setConfigOption({ list: this.searchConfig, data, key: 'month' })
      })
    },
    getSchool() {
      http({ url: '/recruit/common/consulting-school', pre: 'zs' }).then(res => {
        const arr = res.entity_school || []
        const data = arr.map(item => ({ value: item.id, label: item.school_name }))
        data.unshift({ value: '0', label: '全部' })
        setConfigOption({ list: this.searchConfig, data, key: 'school_id' })
      })
    },
    getGrade() {
      http({ url: '/site/grades', pre: 'api1' }).then(res => {
        const data = res.map(item => ({ value: item.id, label: item.name }))
        data.unshift({ value: '0', label: '全部' })
        setConfigOption({ list: this.searchConfig, data, key: 'grade_id' })
      })
    },
    getClass(school_id, grade_id) {
      if (!grade_id || !school_id) return
      http({ url: '/site/role-admin-class', pre: 'api1' }, { grade_id, school_id }).then(res => {
        const data = res.map(item => ({ value: item.class_id, label: item.class_name }))
        setConfigOption({ list: this.searchConfig, data, key: 'class_id' })
      })
    },
    async getData() {
      this.getDataApi('/api2/api/teacher/moral-educationController/record-list')
    },
    onBeforeSearch(val) {
      const { month, ...other } = val
      const data = { ...other }
      if (month) {
        const [start, end] = month.split('-')
        data.day_start = start
        data.day_end = end
      }
      this.search = data
    },
    onBeforeInit() {
      const name = this.$route.query.student_name
      if (name) {
        this.page = 1
        setConfigValue({ list: this.searchConfig, key: 'student_name', changeKey: 'default', value: name })
        this.search.student_name = name
      }
    },
    onBeforeReset() {
      if (getConfigValue({list: this.searchConfig, key: 'student_name', valueKey: 'default'})) {
        setConfigValue({ list: this.searchConfig, key: 'student_name', changeKey: 'default' })
        this.$router.replace('./list')
      }
      setConfigOption({ list: this.searchConfig, data: [], key: 'class_id' })
      this.search = {}
      this.tableData = []
    },
    handleClick(row) {
      this.$router.push(`./detail?id=${row.id}`)
    },
    handDownload() {
      if (JSON.stringify(this.search) === '{}') {
        return this.$message.warning('请先选择筛选条件')
      }
      fileDownload('/api2/api/teacher/moral-educationController/record-list', {...this.search, type: 1}, { name: '德育一对一辅导记录 ', show: true })
    }
  }
}
</script>

<style lang="scss" scoped></style>
